import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import documentDriven_cMiI6j6ghD from "/vercel/path0/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import motion_jmBwdqbgTJ from "/vercel/path0/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import plugin_client_OVoKJro5pc from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
import detect_mobile_mOlIChJdrj from "/vercel/path0/plugins/detect-mobile.ts";
import query_Ux2Qt90xcP from "/vercel/path0/plugins/query.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import wallets_client_liIoFpBztu from "/vercel/path0/plugins/wallets.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  documentDriven_cMiI6j6ghD,
  motion_jmBwdqbgTJ,
  plugin_client_OVoKJro5pc,
  analytics_client_mtqVw3YRXk,
  detect_mobile_mOlIChJdrj,
  query_Ux2Qt90xcP,
  sentry_client_shVUlIjFLk,
  wallets_client_liIoFpBztu
]